import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import request from "../../utils/axios";
import styles from "./InfiniteScroll.module.scss"

const InfiniteScrollComponent = ({ apiEndpoint, renderUI, initialPage = 1 }) => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(initialPage);
  const [dataLength, setDataLength] = useState(null);

  useEffect(() => {
    fetchMoreData();
  }, []);

  const fetchMoreData = async () => {
    const apiUrl = `${apiEndpoint}?page=${page}&page_size=20`;

    try {
      const res = await request({
        method: "get",
        url: apiUrl,
      })
      console.log("response..", res)
      setItems([...items, ...res?.pagination_data?.data]);
      setDataLength(res?.pagination_data?.total);
      setPage(page + 1);
      if (res?.pagination_data?.next === null) {
        setHasMore(false);
        return;
      }
    }
    catch (error) {
      console.error("Error fetching data: ", error);
      setHasMore(false);
    }
  };


  return (
    <div
      id="scrollableDiv"
      style={{
        height: "auto",
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column-reverse',
        scrollbarWidth: "none"
      }}
    >
      <InfiniteScroll
        dataLength={dataLength - (items.length )}
        next={fetchMoreData}
        hasMore={hasMore}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
        loader={
          <p style={{ textAlign: 'center' }}>
            <b>Loading...</b>
          </p>
        }
        scrollableTarget="scrollableDiv"
        height="70vh"
        className={styles.customScrollbar}
      >
        {
          renderUI(items)
        }
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteScrollComponent;