import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import request from '../../../utils/axios';
import './PolicyDocumentDetails.scss'
import { toast } from 'react-toastify';
import { Modal, Select, Table, Radio, Checkbox } from 'antd';
import getScreenSize from '../../../utils/getScreenSize';
import InfiniteScrollComponent from '../../../component/InfiniteScroll/InfiniteScroll';
import ContainerLayout from '../../../component/ContainerLayout/ContainerLayout';
import InfiniteScroll from 'react-infinite-scroll-component';
import back from "../../../assets/Recruitment/back.svg"
import filterImage from '../../../assets/filter.svg'
import { CloseOutlined } from '@ant-design/icons';
import PolicyDocumentEmployeeSearchBar from '../PolicyDocumentEmployeeSearchBar/PolicyDocumentEmployeeSearchBar';

function PolicyDocumentDetails() {
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const navigate = useNavigate()
    const params = useParams()
    const screenSize = getScreenSize();
    const [hasMore, setHasMore] = useState(true);

    const [sendResendMail, setSendResendMail] = useState(false)

    const [selectedName, setSelectedName] = useState("")
    const [showSigned, setShowSigned] = useState(false);
    const [showUnsigned, setShowUnsigned] = useState(false);
    const columns = [
        {
            title: 'Employee Id',
            dataIndex: 'employee_code',
            key: 'employee_code',
        },
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',

            render: (text, record) => <button className={`${text}Button`}>{text}</button>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <>
                    <Select
                        defaultValue={1}
                        onChange={(value) => onHandleChangeForStatus(value, record.employement_number, record.employee_name)}
                        style={{ width: "120px" }}
                    >
                        <Select.Option value={1}>Select</Select.Option>
                        <Select.Option value={2}>Reminder</Select.Option>
                    </Select>
                </>
        }
    ];

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 15, // Number of items per page
        total: 0,
    })

    const [detailList, setDetailList] = useState([])

    const fetchData = async (newPagination, dataList) => {
        const { current } = newPagination || pagination;
        try {
            const response = await request({
                method: "get",
                url: `documentation/admin/policy-document/insights?id=${params.id}&page=${current}&page_size=${pagination.pageSize}`,
            })
            if (newPagination?.current) {
                setDetailList(response?.pagination_data?.data)
            }
            else {
                setDetailList([...detailList, ...response?.pagination_data?.data])
            }

            setPagination({
                ...pagination,
                total: response?.pagination_data?.total,
                current: current + 1
            })

            if (response?.pagination_data?.next === null) {
                setHasMore(false);
                return;
            }
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const onHandleChangeForStatus = (value, employement_number, employee_name) => {
        try {
            if (value === 2) {
                const response = request({
                    method: "post",
                    url: `/documentation/admin/policy-document/reminder?id=${params.id}`,
                    data: {
                        employement_number: employement_number
                    }
                })
                setSendResendMail(true)
                setSelectedName(employee_name)
            }

        } catch (error) {
            console.log(error)
        }
    }
    // const handleSignedEmployee =  async() => {
    //     try{

    //         const response = await request({
    //             method: "get",
    //             url: `/documentation/admin/policy-document/insights?page=1&page_size=10&id=1&status_id=1`
    //         })
    //         if(response){
    //             console.log("signed data..." + response)
    //             // setDetailList(response)
    //             setOpenFilterModal(false)
    //         }
    //     }catch(error){
    //         console.log(error);
    //     }

    // };
    const handleSignedEmployee = async () => {
        try {
            const response = await request({
                method: "get",
                url: `documentation/admin/policy-document/insights?id=${params.id}&page=1&page_size=10&status_id=${1}`,
            })
            if (response) {
                setDetailList(response?.pagination_data?.data)
                setOpenFilterModal(false)

            }
            // console.log(response)
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const handleUnSingedEmployee = async () => {
        try {
            const response = await request({
                method: "get",
                url: `documentation/admin/policy-document/insights?id=${params.id}&page=1&page_size=10&status_id=${2}`,
            })
            if (response) {
                setDetailList(response.pagination_data?.data)
                setOpenFilterModal(false)
            }

            console.log(response)
        }
        catch (error) {
            console.log("error", error);
        }
    }
    useEffect(() => {
        fetchData()
    }, [])

    const renderUI = (dataList) => {
        return (
            <div style={{}}>
                {dataList?.map((item, index) => {
                    return (
                        <div key={index} style={{ marginTop: "20px" }}>
                            <div style={{
                                padding: "0px 16px",
                            }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",

                                        marginBottom: "20px",
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: "600",
                                            color: "#161616",
                                            margin: "0",
                                            marginTop: "10px"
                                        }}
                                    >
                                        {item?.employee_name}
                                    </p>
                                    <Select
                                        defaultValue={1}
                                        onChange={(value) => onHandleChangeForStatus(value, item.employement_number)}
                                        style={{
                                            width: "130px",
                                        }}
                                    >
                                        <Select.Option value={1}>Select</Select.Option>
                                        <Select.Option value={2}>Reminder</Select.Option>
                                    </Select>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        // padding: "0px 16px",
                                    }}
                                >
                                    <p style={{ fontSize: "16px", fontWeight: "500", color: "#4A4A4A" }}>Employee ID</p>
                                    <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616" }}>{item?.employee_code}</p>
                                </div>
                                <div
                                    style={{

                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <p style={{ fontSize: "14px", fontWeight: "500", color: "#4A4A4A" }}>Status</p>
                                    <button className={`${item.status}Button`}>{item.status}</button>
                                </div>
                            </div>
                            <div style={{ height: "2px", width: "100%", background: "#F7F8FC", margin: "5px 0px" }}></div>

                        </div>
                    );
                })}
            </div>
        );
    };

    const renderUIDesktop = (dataList) => {
        return <Table
            columns={columns}
            dataSource={dataList}
            pagination={false}
            sticky={{ offsetHeader: 0 }}
        />
    }

    return (
        <div>
            {
                screenSize.width < 768
                    ?
                    <div >
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px", marginTop: "80px" }}>
                            <p style={{ fontSize: "24px", fontWeight: "400", color: "#161616" }}>Policy Document Details</p>
                            <img src={filterImage}
                                onClick={() => { setOpenFilterModal(true) }}
                                style={{
                                    height: "22px",
                                    width: "22px"
                                }}
                            />

                        </div>


                        <div>
                            <InfiniteScroll
                                dataLength={detailList?.length}
                                next={fetchData}
                                // hasMore={hasMore}
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                                loader={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>Loading...</b>
                                    </p>
                                }
                                scrollableTarget="scrollableDiv"
                                height="70vh"
                            // className={styles.customScrollbar}
                            >
                                {renderUI(detailList)}
                            </InfiniteScroll>
                            <button style={{
                                position: "absolute",
                                left: "10%",
                                top: "80vh",
                                zIndex: 1,
                                width: "80%",
                                backgroundColor: "rgb(0, 128, 128)",
                                color: "white",
                                height: "40px",
                                padding: "0px 15px",
                                border: "none",
                                borderRadius: "5px"
                            }}
                                onClick={() => {
                                    navigate(`/doc/bulk-reminder/${params.id}`)
                                }}
                            >Bulk Reminder</button>

                            {
                                openFilterModal
                                &&
                                <div style={{ width: "100%", height: "100vh", position: "fixed", top: "0", zIndex: "99999", background: "rgba(0, 0, 0, 0.5)" }}>
                                    <div style={{ width: "100%", height: "320px", background: "white", position: "absolute", bottom: "0", borderTopLeftRadius: "25px", borderTopRightRadius: "25px" }}>
                                        <div style={{ width: "100%", height: "300px", background: "white", position: "absolute", bottom: "0", overflow: "auto" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 15px 0px 15px" }}>
                                                <p style={{ fontSize: "18px", fontWeight: "600" }}>Filter</p>
                                                <CloseOutlined style={{ width: "20px", height: "20px" }} onClick={() => setOpenFilterModal(false)} />
                                            </div>
                                            <div style={{ padding: "0px 15px 0px 15px" }}>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <p style={{ padding: "10px", paddingLeft: "0px", fontSize: "18px", marginBottom: "0px" }} onClick={handleSignedEmployee}>Signed</p>
                                                </div>


                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <p style={{ padding: "10px", paddingLeft: "0px", fontSize: "18px", marginBottom: "0px" }} onClick={handleUnSingedEmployee}>Unsigned</p>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            }
                            {/* <InfiniteScrollComponent
                                apiEndpoint={`/documentation/admin/policy-document/insights?id=${48}`}
                                renderUI={renderUI}
                                initialPage={1}
                            /> */}
                        </div>
                    </div>
                    :
                    <ContainerLayout>
                        <div style={{ padding: "20px", background: "white", borderRadius: "10px" }}>
                            <p onClick={() => { navigate("/admin/docs") }} style={{ cursor: "pointer" }}>
                                <img src={back} alt="back" />
                                <span style={{ paddingLeft: "10px" }}>Back</span>
                            </p>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Policy Document Details </p>
                                <PolicyDocumentEmployeeSearchBar
                                    setTableData={setDetailList}
                                    setHasMore={setHasMore}
                                    fetchTableDataFromApi={fetchData}
                                    setPagination={setPagination}
                                    placeholderText="Search for employees"
                                    empType="admin"
                                />
                                <button className="commonBtn"
                                    style={{
                                        border: "none",
                                        color: "white",
                                        background: "#008080",
                                        borderRadius: "4px",
                                        height: "36px",
                                        // padding: "0px 10px",
                                        width: "120px",
                                        cursor: "pointer",
                                        marginBottom: "20px",
                                    }}
                                    onClick={() => {
                                        navigate(`/doc/bulk-reminder/${params.id}`)
                                    }}


                                >Bulk Reminder</button>
                            </div>

                            {/* <CommonTable
                                columns={columns}
                                data={detailList}
                                pagination={pagination}
                                setPagination={setPagination}
                                handleRowClick={() => { }}
                            /> */}
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={pagination.total - (detailList.length)}
                                    next={fetchData}
                                    hasMore={hasMore}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="75vh"
                                    className="customScrollbar"
                                >
                                    {
                                        renderUIDesktop(detailList)
                                    }
                                </InfiniteScroll>
                            </div>
                        </div>
                    </ContainerLayout>
            }


            <Modal
                centered
                open={sendResendMail}
                footer={null}
                onOk={() => {
                    setSendResendMail(false)
                    setSelectedName("")
                }}
                onCancel={() => {
                    setSendResendMail(false)
                    setSelectedName("")
                }}
            >
                <center>
                    <img
                        src={
                            process.env.REACT_APP_CDN
                                ? process.env.REACT_APP_CDN
                                : "/media/Attendance/forgotImg.svg"
                        }
                        alt="forgotImg"
                    />
                    <p>Email Sent Successfully to {selectedName}</p>
                </center>
            </Modal>

        </div>

    )

}

export default PolicyDocumentDetails