import React, { useEffect, useRef, useState } from "react";
import back from "../../../../../assets/Recruitment/back.svg";
import request from "../../../../../utils/axios";
import { Button, Form, Modal, Select, Table, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import DynamicTable from "../../../../../component/TableWithPagination/TableWithPagination";
// import EmployeeAttendancePolicyManagerSearchBar from "../EmployeeAttendancePolicyManagerSearchBar/EmployeeAttendancePolicyManagerSearchBar";
import CommonTable from "../../../../../component/CommonTable/CommonTable";
import getScreenSize from "../../../../../utils/getScreenSize";
import mobileStyles from "./MobileMyTeamEmployeeRegularisationFullView.module.scss";
import InfiniteScrollComponent from "../../../../../component/InfiniteScroll/InfiniteScroll";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import ContainerLayout from "../../../../../component/ContainerLayout/ContainerLayout";
import ToolTip from "../../../../../component/ToolTip/ToolTip";
import {LoadingOutlined} from "@ant-design/icons"
function MyTeamEmployeeRegularisationFullView() {
  const navigate = useNavigate();
  const screenSize = getScreenSize();
  const ref = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const [myTeamEmployeeLoading, setMyTeamEmployeeLoading] = useState([])
  const [myTeamEmployeeRejectLoading, setMyTeamEmployeeRejectLoading] = useState([])


    const myTeamAppliedLeaveDetailsColumns = [
      {
        title: "Name",
        dataIndex: "employee_name",
        key: "employee_name",
      },
      {
        title: "Attendance Date",
        dataIndex: "attendance_date",
        key: "attendance_date",
      },
      {
        title: "Check In",
        dataIndex: "old_check_in_time",
        key: "old_check_in_time",
      },
      {
        title: "New Check In",
        dataIndex: "new_check_in_time",
        key: "new_check_in_time",
        // render: (text, record) => (
        //   <>
        //     <span>
        //       {text}{" "}
        //       <Popover
        //         placement="top"
        //         title="Leaves"
        //         content={leaveDetailPopUp(record)}
        //       >
        //         <InfoCircleOutlined />
        //       </Popover>
        //     </span>
        //   </>
        // ),
  
      },
      {
        title: "Check Out",
        dataIndex: "old_check_out_time",
        key: "old_check_out_time",
      },
          {
        title: "New Check Out",
        dataIndex: "new_check_out_time",
        key: "new_check_out_time",
      },
      {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        render: (text, record) => (
          <>
            <div style={{ display: "flex", gap: "10px" }}>
              <button
                className="approveButton"
                onClick={() => {
                  const updatedState = [...myTeamEmployeeLoading]; 
                  const index = updatedState.findIndex(item => item.id === record.id);
                  if (index !== -1) {  
                    updatedState[index] = {
                      ...updatedState[index],  
                      isApproved: true,  
                    };
                  }
                  setMyTeamEmployeeLoading(updatedState)
                  rejectOrApproveRegularization(record.id, true);

                }}
              >
                {myTeamEmployeeLoading.find(item => item.id === record.id)?.isApproved ? (
                  <Spin indicator={<LoadingOutlined spin className="approveButtonLoader" />} />
                ) : (
                  "Approve"
                )}
              </button>
              <button
                className="rejectButton"
                onClick={() => {
                  const updatedState = [...myTeamEmployeeRejectLoading]; 
                  const index = updatedState.findIndex(item => item.id === record.id);
                  if (index !== -1) {  
                    updatedState[index] = {
                      ...updatedState[index],  
                      isReject: true,  
                    };
                  }
                  setMyTeamEmployeeRejectLoading(updatedState)
                  rejectOrApproveRegularization(record.id, false);
                }}
              >
                {myTeamEmployeeRejectLoading.find(item => item.id === record.id)?.isReject ? (
                  <Spin indicator={<LoadingOutlined spin className="rejectButtonLoader" />} />
                ) : (
                  "Reject"
                )}
              </button>
              {/* <div style={{display: "flex", alignItems: "center" }}>
                <div >
                  <img
                  src={view}
                  style={{
                    height:"22px",
                    width: "22px",
                  }}
                  onClick={()=>{
                    setTeamDetailModel(true)
                    setSelectedTeamDetailData(record)
                  }}
                  />
                </div>
              </div> */}
            </div>
          </>
        ),
      },
    ];

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12, // Number of items per page
    total: 0,
  });

  const [allRegularizationRequest, setAllRegularizationRequest] = useState([]);

  const fetchAllRegularizationDetails = async (pageParams, dataList) => {
    try {
      const { current } = pageParams || pagination;
      const data = await request({
        method: "get",
        url: `attendance/manager/regularisation/list?page=${current}&page_size=${pagination.pageSize}`,
      });
        const isApproved = false;
        const isReject = false;
      const updatedData = data.pagination_data.data.map((item) => ({
        ...item,
        isApproved,
        isReject,
      }));

      if (pageParams) {
        setAllRegularizationRequest([
          ...dataList,
          ...data.pagination_data.data,
        ]);
      } else {
        setAllRegularizationRequest([
          ...allRegularizationRequest,
          ...data.pagination_data.data,
        ]);
      }
        setMyTeamEmployeeLoading(updatedData)
        setMyTeamEmployeeRejectLoading(updatedData)
      setPagination({
        ...pagination,
        total: data?.pagination_data?.total,
        current: current + 1,
      });
      if (data?.pagination_data?.next === null) {
        setHasMore(false);
        return;
      }
      return data;
    } catch (error) {
      console.log(error);
      setHasMore(false);
    }
  };


  useEffect(() => {
    // if (screenSize.width > 768) {
    fetchAllRegularizationDetails();
    // }
  }, []);



    const rejectOrApproveRegularization = async (id, isApproved, is_withrawn_after_approval) => {
      try {
          await request({
            method: "post",
            url: `/attendance/regularization/action?id=${id}`,
            data: {
              is_approved: isApproved,
            },
          });
        setTimeout(() => {
          fetchAllRegularizationDetails(pagination, dataList);
        }, 1000);
        console.log("myTeamEmployeeLoading", myTeamEmployeeLoading)
        if (isApproved)
          toast.success("Regularisation Approved Successfully", {
            position: "bottom-left",
          });
        else {
          toast.success("Regularisation Rejected Successfully", {
            position: "bottom-left",
          });
        }

      const pagination = {
        current: 1,
        pageSize: 12, // Number of items per page
        total: 0,
      };
      const dataList = [];
      setHasMore(true);
      fetchAllRegularizationDetails(pagination, dataList);

      } catch (error) {
        console.log(error);
        if(isApproved === true){
          const updatedState = [...myTeamEmployeeLoading]; 
          const index = updatedState.findIndex(element => element.id === id);
          if (index !== -1) {  
            updatedState[index] = {
              ...updatedState[index],  
              isApproved: false,  
            };
          }
          console.log("ApprovedState", updatedState)
          setMyTeamEmployeeLoading(updatedState)
        }else{
          const updatedState = [...myTeamEmployeeLoading]; 
          const index = updatedState.findIndex(element => element.id === id);
          if (index !== -1) {  
            updatedState[index] = {
              ...updatedState[index],  
              isReject: false,  
            };
          }
          console.log("RejectState", updatedState)
          setMyTeamEmployeeRejectLoading(updatedState)
        }
        if (isApproved) {
          toast.error("Regularisation Request could not be approved, please try after some time", {
            position: "bottom-left",
          });
        } else {
          toast.error("Regularisation Request could not be rejected, please try after some time", {
            position: "bottom-left",
          });
        }
      }
    };


  // useEffect(() => {
  //     ref?.current?.scrollIntoView({ behavior: 'instant' })
  //     document.body.scrollTop = document.documentElement.scrollTop = 0;
  // }, []);

  const renderUI = (dataList) => {
    return dataList.map((item, index) => {
      return (
        <div>
          <div
            className={mobileStyles.cardContainer}
            style={{ marginBottom: "10px" }}
          >
            <p
              className={mobileStyles.value}
              style={{ margin: "0px", fontWeight: "600" }}
            >
              {item.employee_name}
            </p>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px" }}>
            <p>
              Date
            </p>
            <p>
              {item.attendance_date}
            </p>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px" }}>
            <p>
              Old Check In Time
            </p>
            <p>
              {item.old_check_in_time}
            </p>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px" }}>
            <p>
              New Check In Time
            </p>
            <p>
              {item.new_check_in_time}
            </p>
          </div>


          <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px" }}>
            <p>
              Old Check Out Time
            </p>
            <p>
              {item.old_check_out_time}
            </p>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px" }}>
            <p>
              New Check Out Time
            </p>
            <p>
              {item.new_check_out_time}
            </p>
          </div>

          <div className={mobileStyles.cardContainer}>
            <p className={mobileStyles.value}>Reason</p>
          </div>
          
          <div className={mobileStyles.cardContainer}>
            <p className={mobileStyles.value} style={{ fontWeight: "600" }}>{item.comment}</p>
          </div>


              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", marginBottom: "10px", marginTop: "5px", padding : "0px 16px" }}>
                  <button
                    className="approveButton"
                      onClick={() => {
                        const updatedState = [...myTeamEmployeeLoading]; 
                        const index = updatedState.findIndex(element => element.id === item.id);
                        if (index !== -1) {  
                          updatedState[index] = {
                            ...updatedState[index],  
                            isApproved: true,  
                          };
                        }
                        console.log("updatedState", updatedState)
                        setMyTeamEmployeeLoading(updatedState)
                        rejectOrApproveRegularization(item.id, true);
                      }}
                  >
                    {myTeamEmployeeLoading.find(element => element.id === item.id)?.isApproved ? (
                      <Spin indicator={<LoadingOutlined spin className="approveButtonLoader" />} />
                    ) : (
                      "Approve"
                    )}
                  </button>
                  <button
                    className="rejectButton"
                      onClick={() => {
                        const updatedState = [...myTeamEmployeeLoading]; 
                        const index = updatedState.findIndex(element => element.id === item.id);
                        if (index !== -1) {  
                          updatedState[index] = {
                            ...updatedState[index],  
                            isReject: true,  
                          };
                        }
                        console.log("updatedState", updatedState)
                        setMyTeamEmployeeRejectLoading(updatedState)
                        rejectOrApproveRegularization(item.id, false);
                      }}
                  >
                    {myTeamEmployeeRejectLoading.find(element => element.id === item.id)?.isReject ? (
                      <Spin indicator={<LoadingOutlined spin className="rejectButtonLoader" />} />
                    ) : (
                      "Reject"
                    )}
                  </button>
              </div>
          {allRegularizationRequest.length - 1 !== index && (
            <hr style={{ border: "1px solid #F0F0F0", width: "100%" }} />
          )}
        </div>
      );
    });
  };

  const renderUIDesktop = (dataList) => {
    return (
      <Table
        columns={myTeamAppliedLeaveDetailsColumns}
        dataSource={dataList}
        pagination={false}
        sticky={{ offsetHeader: 0 }}
      />
    );
  };

  return (
    <div ref={ref}>
      {screenSize.width < 768 ? (
        <div className={mobileStyles.employeeAttendancePolicyManager}>
          <div className={mobileStyles.headingContainer}>
            <p className={mobileStyles.heading}>
              Regularisation Manager
            </p>
          </div>
          <div
            id="scrollableDiv"
            style={{
              height: "auto",
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <InfiniteScroll
              dataLength={
                pagination.total - allRegularizationRequest.length
              }
              next={fetchAllRegularizationDetails}
              hasMore={hasMore}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              loader={
                <p style={{ textAlign: "center" }}>
                  <b>Loading...</b>
                </p>
              }
              scrollableTarget="scrollableDiv"
              height="70vh"
            >
              {renderUI(allRegularizationRequest)}
            </InfiniteScroll>
          </div>
        </div>
      ) : (
        <ContainerLayout>
          <div>
            <p onClick={() => { navigate(-1) }} style={{ cursor: "pointer" }}>
              <img src={back} alt="back" />
              <span style={{ paddingLeft: "10px" }}>Back</span>
            </p>
                        {/* <h1>Attendance</h1> */}
            <div style={{ padding: "20px", background: "white" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                    paddingBottom: "20px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    Regularisation Manager
                  </p>
                  {/* <EmployeeAttendancePolicyManagerSearchBar
                    setTableData={setAllRegularizationRequest}
                    setHasMore={setHasMore}
                    fetchTableDataFromApi={fetchAllRegularizationDetails}
                    setPagination={setPagination}
                    placeholderText="Search for employees"
                    empType="admin"
                  /> */}
                </div>
              </div>
              <div
                id="scrollableDiv"
                style={{
                  height: "auto",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <InfiniteScroll
                  dataLength={
                    pagination.total - allRegularizationRequest.length
                  }
                  next={fetchAllRegularizationDetails}
                  hasMore={hasMore}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  loader={
                    <p style={{ textAlign: "center" }}>
                      <b>Loading...</b>
                    </p>
                  }
                  scrollableTarget="scrollableDiv"
                  height="70vh"
                >
                  {renderUIDesktop(allRegularizationRequest)}
                </InfiniteScroll>
              </div>
              {/* <CommonTable
                                columns={employeeAttendancePolicyMangerColumns}
                                data={allRegularizationRequest}
                                pagination={pagination}
                                setPagination={setPagination}
                                handleRowClick={() => { }}
                            /> */}
            </div>
          </div>
        </ContainerLayout>
      )}
    </div>
  );
}

export default MyTeamEmployeeRegularisationFullView;
