import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Select,
  Switch,
  Row,
  Col,
  Spin,
  Badge,
} from "antd";
import dayjs from "dayjs";
import { Link, useNavigate, useLocation } from "react-router-dom";

import "./SideMenuWithHeader.scss";
import request from "../../utils/axios";

// import SearchBar from '../SearchBar/SearchBar';

import menuList from "../../data/menuList";
import AdminMenuList from "../../data/adminMenuList";
import profileImg from "../../assets/Leave/profileImg.svg";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import headerprofile from "../../assets/headerprofile.svg";
import logout from "../../assets/logout.svg";
// import desktopLogo from "../../assets/desktopLogo.png";
import InitiateSepration from "../../assets/InitiateSepration.png";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfileInRedux } from "../../redux/reducer/UserProfileReducer";
import Loader from "../Loader/Loader";
import getScreenSize from "../../utils/getScreenSize";
// import userProfileImg from "../../assets/user.png";
import { toast } from "react-toastify";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import SuspenseWrapper from "../SuspenseWrapper/SuspenseWrapper";
import { useRoutes } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../../component/ErrorFallback/ErrorFallback";
const { Header, Footer, Sider, Content } = Layout;

function SideMenuWithHeader({ content }) {
  const [activeItem, setActiveItem] = useState(null);
  const [profileModel, setProfileModel] = useState(false);
  const role_list = JSON.parse(localStorage.getItem("role_list"));
  const is_admin = JSON.parse(localStorage.getItem("is_admin"));
  const role = localStorage.getItem("role");
  const is_org_head = JSON.parse(localStorage.getItem("is_org_head"));
  const parent_company_id = JSON.parse(localStorage.getItem("parent_company_id"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile);
  const profileRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const handleItemClick = (key) => {
    setActiveItem(key);
  };
  const [initiateSeprationModel, setInitialSeperationModel] = useState(false);
  const screenSize = getScreenSize();
  const switchAvatar = (userType) => {
    localStorage.setItem("role", userType);
    navigate("/dashboard", { replace: true });
  };
  const [resignationDetails, setResignationDetails] = useState({
    resignation_date: "",
    last_working_date: "",
    requested_date: "",
    recovery_days: "",
    reason: "",
  });
  const [noticePeriod, setNoticePeriod] = useState(null);
  const [menuNotificaitonDetails, setMenuNotificationDetails] = useState({
    inbox: ""
  })
  const getNoticePeriod = async () => {
    const apiUrl = `employee/notice-period`;

    try {
      const res = await request({
        method: "POST",
        url: apiUrl,
      });
      setNoticePeriod(res.notice_period);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const logoutUser = async () => {
    try {
      let deviceType;
      if (screenSize.width < 768) {
        // mobile
        deviceType = 0;
      } else if (screenSize.width < 1440) {
        // laptop
        deviceType = 1;
      } else {
        // desktop
        deviceType = 2;
      }

      await request({
        method: "post",
        url: `/users/logout`,
        data: {
          device_type: deviceType,
        },
      });

      localStorage.clear();
      let logout = "logout";
      if (
        window.ReactNativeWebView &&
        typeof window.ReactNativeWebView.postMessage === "function"
      ) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ logout }));
      } else {
        console.warn("ReactNativeWebView is not defined.");
      }
      setProfileModel(!profileModel);
      navigate("/login", { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  let location = useLocation();
  const currentUrl = location.pathname;

  const fetchUserProfileImage = async () => {
    try {
      const data = await request({
        method: "GET",
        url: "/employee/meta-data",
      });

      dispatch(
        setUserProfileInRedux({
          ...userProfile,
          profile_image: data.profile_image,
        })
      );

      setMenuNotificationDetails({
        ...menuNotificaitonDetails,
        inbox: data.data.inbox_notification
      })
    } catch (error) {
      console.log(error);
    }
  };

  const [companyNameList, setCompanyNameList] = useState();
  const fetchCompanyName = async () => {
    try {
      const data = await request({
        method: "GET",
        url: "/admin/organizations/list",
      });
      console.log(data.data);
      setCompanyNameList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserProfileImage();
    handlelogo();
    if (is_org_head) {
      fetchCompanyName();
    }
  }, []);
  useEffect(() => {
    if (initiateSeprationModel) {
      getNoticePeriod();
    }
  }, [initiateSeprationModel]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        // Clicked outside the popup, so close it
        setProfileModel(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role === "Admin") {
      const urlContainAdmin = currentUrl.split("/")[1] === "admin";
      let moduleName = currentUrl.split("/")[2];

      if (urlContainAdmin && is_admin) {
        const isAuthorized = role_list?.includes(moduleName);
        if (isAuthorized) {
          // navigate(currentUrl);
          setActiveItem(moduleName);
        } else {
          navigate("/admin/dashboard");
        }
      } else if (!urlContainAdmin) {
        navigate(currentUrl);
      }
    } else {
      const moduleName = currentUrl.split("/")[1];
      localStorage.setItem("activeMenu", moduleName);

      setActiveItem(moduleName);
    }
  }, [currentUrl]);

  const [searchEmployeeList, setSearchEmployeeList] = useState([]);

  const search = async (data) => {
    try {
      const response = await request({
        method: "get",
        url: `leave/search/employees/?employee_name=${data}&type=admin`,
      });

      setSearchEmployeeList(response);
    } catch (err) {
      console.log(err);
    }
  };

  const initiateSeparation = async () => {
    console.log(resignationDetails);
    setLoading(true);
    try {
      const payload = {
        resignation_date: dayjs(resignationDetails.resignation_date).format(
          "YYYY-MM-DD"
        ),
        requested_date: dayjs(resignationDetails.requested_date).format(
          "YYYY-MM-DD"
        ),
        recovery_days: resignationDetails.recovery_days,
        last_working_date: resignationDetails.last_working_date,
        reason: resignationDetails.reason,
      };
      console.log(payload);
      await request({
        method: "post",
        url: `/employee/resignation/create`,
        data: payload,
      });

      toast.success("Resignation Initiated Successfully", {
        position: "bottom-left",
      });
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data.message, {
        position: "bottom-left",
      });
    } finally {
      setLoading(false);
      setInitialSeperationModel(false);
      setResignationDetails({});
    }
  };
  const handleFormChange = (changedValues, allValues) => {
    setResignationDetails(allValues);
    console.log(changedValues);
    console.log(allValues);
    console.log(resignationDetails);

    // Check if the resignation_date has changed
    if (changedValues.resignation_date) {
      const resignationDate = changedValues.resignation_date;
      const lastWorkingDate = dayjs(resignationDate)
        .add(noticePeriod, "day")
        .format("YYYY-MM-DD");

      // Update the state with the new last working date
      setResignationDetails((prev) => ({
        ...prev,
        last_working_date: lastWorkingDate,
      }));
    }
    if (changedValues.requested_date) {
      const last_working_date = allValues.last_working_date;
      const requested_date = allValues.requested_date;
      const recoveryDays = dayjs(last_working_date).diff(requested_date, "day");

      // Update the state with recovery days if needed
      setResignationDetails((prev) => ({
        ...prev,
        recovery_days: recoveryDays > 0 ? recoveryDays : 0,
      }));
    }
  };
  const getMaxRequestedDate = (resignationDate) => {
    return dayjs(resignationDate).add(noticePeriod, "day");
  };

  const onChangeCompany = (value) => {
    localStorage.setItem("childCompany", value)
    window.location.reload()
  }
  const [logo, setLogo] = useState()
  const handlelogo = async () =>{
    try{
      const GetLogo = await request({
        method: 'GET',
        url: `/admin/company-logo?org_id=${parent_company_id}`,
      })
      // console.log(GetLogo?.data.company_logo);
      setLogo(GetLogo?.data.company_logo);
    }catch(error){
      console.log(error)
    }
  }

  const handleError = async (error, errorInfo) => {
    const baseUrl = process.env.REACT_APP_FRONT_APP_BASE_URL;
    const url = window.location.href;
    const userId = localStorage.getItem("user_id");
    const employeeId = localStorage.getItem("employee_number");
    try {
      await request({
        method: "post",
        url: "users/mailer/fe",
        data: {
          subject: "Error Details",
          message: `
          Tenant Name: ${url.split(baseUrl)[1].split("/")[1]}
          url : ${url}
          userId : ${userId}
          employeeId : ${employeeId}
          error: ${error}
          errorInfo: ${errorInfo.toString()}
          `,
        }
      })
      console.log("Error details sent via email", error, errorInfo);
    } catch (err) {
      console.error("Failed to send error details via email:", err);
    }
  };

  return (
    <div className="sideMenuWithHeader">
      <Layout>
        <Sider width={90} className="sideMenu">
          <div className="logo">
            <img
              src={logo? logo : "/media/desktopLogo.png"}
              // src={process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/desktopLogo.png"}
              alt="Logo"
              width={80}
              height={50}
              onClick={() => navigate("/dashboard", { replace: true })}
            />
          </div>
          <div className="sideMenuList">
            <ul>
              {role === "Admin" ? (
                <AdminMenuList />
              ) : (
                menuList.map((item, index) => {
                  return (
                    <Link to={item.path}>
                      <li
                        key={index}
                        className={` ${activeItem === item.key && "active"}`}
                        onClick={() => handleItemClick(item.key)}
                      >
                        <div className="menuItemContainer">
                          {
                            menuNotificaitonDetails[item.key]
                              ?
                              <Badge
                                count={
                                  <div style={{
                                    background: "#EDFEFE", width: "25px", height: "15px", fontSize: "12px",
                                    borderTopLeftRadius: "5px",
                                    borderTopRightRadius: "5px",
                                    borderBottomRightRadius: "5px",
                                    color: "#008080",
                                    fontWeight: "500",
                                    margin: "0px -8px"
                                  }}
                                    offset={[100, 100]}
                                  >
                                    {menuNotificaitonDetails[item.key]}
                                  </div>
                                }
                              >
                                <img
                                  src={
                                    activeItem === item.key
                                      ? item.activeImage
                                      : item.image
                                  }
                                  className="menuItemImage"
                                />
                              </Badge>
                              :
                              <img
                                src={
                                  activeItem === item.key
                                    ? item.activeImage
                                    : item.image
                                }
                                className="menuItemImage"
                              />
                          }

                          <p
                            className={` ${activeItem === item.key
                              ? "activeMenuItemLabel"
                              : "menuItemLabel"
                              }`}
                          >
                            {item.label}
                          </p>
                        </div>
                      </li>
                    </Link>
                  );
                })
              )}
            </ul>
          </div>
        </Sider>
        <Layout>
          <Header className="header">
            {/* <div className="globalSearch">
              <input
                type="text"
                placeholder="Search Employee"
                onChange={(e) => search(e.target.value)}
              />
              <SearchOutlined className="searchIcon" />
              {searchEmployeeList.length > 0 && (
                <ul>
                  {searchEmployeeList.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        navigate(`/user-profile/${item.employement_number}`, {
                          replace: true,
                        });
                        setSearchEmployeeList([]);
                      }}
                    >
                      <div>{item.employee_name}</div>
                    </li>
                  ))}
                </ul>
              )}
            </div>   */}

            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                {is_admin && (
                  // <div className="avatar-container">
                  //     <div
                  //         onClick={() => switchAvatar('Emp')}
                  //         className={role === 'Emp' ? 'empSelected flexCenter' : 'empNotSelected flexCenter'}
                  //     >
                  //         <span>Emp</span>
                  //     </div>
                  //     {
                  //         JSON.parse(is_admin) && <div
                  //             onClick={() => { is_admin && switchAvatar('Admin') }}
                  //             className={role === 'Admin' ? 'adminSelected flexCenter' : is_admin ? 'adminNotSelected flexCenter' : 'adminNotSelectedDisable flexCenter'}
                  //         >
                  //             Admin
                  //         </div>
                  //     }
                  // </div>
                  <div className="avatar-container" style={{ gap: "30px" }}>
                    {role === "Admin" && is_org_head && (
                      <Select
                        defaultValue={
                          +localStorage.getItem("childCompany")
                            ? +localStorage.getItem("childCompany")
                            : ""
                        }
                        style={{ width: 150 }}

                        // onChange={(value) =>
                        //   localStorage.setItem("childCompany", value)

                        // }
                        onChange={(value) => {
                          onChangeCompany(value)
                        }}
                      >
                        <Select.Option value="" disabled>
                          Select Org
                        </Select.Option>
                        {companyNameList &&
                          companyNameList?.map((item, index) => {
                            return (
                              <Select.Option value={item.id}>
                                {item.org_name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    )}

                    <div>
                      <Switch
                        checked={role === "Admin" ? true : false}
                        onChange={(e) => {
                          switchAvatar(e ? "Admin" : "Emp");
                          if (e) {
                            navigate("/admin/dashboard", { replace: true });
                          } else {
                            navigate("/dashboard", { replace: true });
                          }
                        }}
                      />
                      <span style={{ fontWeight: "600", marginLeft: "4px" }}>
                        {" "}
                        Admin
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <div
                  style={{
                    position: "absolute",
                    top: "16px",
                    right: "50px",
                    zIndex: "999",
                  }}
                  onClick={() => {
                    setProfileModel(!profileModel);
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      border: "2px solid #3D2879",
                      height: "36px",
                      width: "36px",
                      borderRadius: "50%",
                      color: "black",
                      fontSize: "10px",
                      fontWeight: "600",
                      zIndex: "1"
                    }}
                  >
                    <img
                      src={
                        userProfile?.profile_image
                          ? userProfile.profile_image
                          : process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/user.png"
                      }
                      alt=""
                      width={32}
                      height={32}
                      style={{
                        borderRadius: "50%",
                        top: "0px",
                        right: "0px",
                        position: "absolute",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              {profileModel && (
                <div class="arrowContainer" ref={profileRef}>
                  <ul
                    style={{
                      listStyleType: "none",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <li
                      onClick={() => {
                        navigate("/user-profile");
                        setProfileModel(!profileModel);
                      }}
                      style={{
                        lineHeight: "50px",
                        marginLeft: "20px",
                      }}
                    >
                      <img src={process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/headerprofile.svg"} alt="" />
                      <span
                        style={{
                          color: "#8E8E8E",
                          fontWeight: "400",
                          fontSize: "18px",
                          marginLeft: "10px",
                        }}
                      >
                        Profile
                      </span>
                    </li>
                    <li
                      onClick={() => logoutUser()}
                      style={{
                        lineHeight: "50px",
                        marginLeft: "20px",
                      }}
                    >
                      <img src={process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/logout.svg"} alt="Avatar" />
                      <span
                        style={{
                          color: "#8E8E8E",
                          fontWeight: "400",
                          fontSize: "18px",
                          marginLeft: "10px",
                        }}
                      >
                        Logout
                      </span>
                    </li>
                    <li
                      onClick={() => setInitialSeperationModel(true)}
                      style={{
                        lineHeight: "50px",
                        marginLeft: "20px",
                      }}
                    >
                      <img
                        src={process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/InitiateSepration.png"}
                        alt="Avatar"
                        width={15}
                        height={15}
                      />
                      <span
                        style={{
                          color: "#8E8E8E",
                          fontWeight: "400",
                          fontSize: "18px",
                          marginLeft: "10px",
                        }}
                      >
                        Initiate Sepration
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </Header>
          <Content style={{ height: "93vh", overflow: "auto" }}>
            <SuspenseWrapper>
              <ErrorBoundary
                fallback={<ErrorFallback />}
                onError={handleError}
              >
                {content}
              </ErrorBoundary>
            </SuspenseWrapper>
          </Content>
        </Layout>
      </Layout>

      <Modal
        centered
        open={initiateSeprationModel}
        onOk={() => setInitialSeperationModel(false)}
        onCancel={() => setInitialSeperationModel(false)}
        footer={null}
        width={600}
        style={{ padding: "20px" }}
      >
        <p style={{ fontSize: "24px" }}>Initiate Separation</p>

        <Form
          layout="vertical"
          onFinish={initiateSeparation}
          onValuesChange={handleFormChange}
          form={form}
          autoComplete="on"
          fields={[
            {
              name: ["resignation_date"],
              value: resignationDetails.resignation_date,
            },
            {
              name: ["last_working_date"],
              value: resignationDetails.last_working_date,
            },
            {
              name: ["requested_date"],
              value: resignationDetails.requested_date,
            },
            {
              name: ["recovery_days"],
              value: resignationDetails.recovery_days,
            },
            {
              name: ["reason"],
              value: resignationDetails.reason,
            },
          ]}
        >
          <Form.Item
            label="Resignation Date"
            name="resignation_date"
            rules={[
              {
                required: true,
                message: "Please select resignation date",
              },
            ]}
          >
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              defaultValue={dayjs(resignationDetails.startTime, "YYYY-MM-DD")}
              format={"YYYY-MM-DD"}
              name="resignation_date"
              inputReadOnly={screenSize.width < 768}
            // disabledDate={(current) =>
            //   current && current < dayjs().startOf("day")
            // }
            // defaultValue={moment()} // Set current date as default
            />
          </Form.Item>

          <Form.Item label="Last Working Date" name="last_working_date">
            {/* <DatePicker
              // className="leaveInputField"
              size="large"
              style={{width: '100%'}}
              // defaultValue={moment()} // Set current date as default
              disabled // Disable the DatePicker
            /> */}
            <Input size="large" disabled name="last_working_date" />
          </Form.Item>

          <Form.Item
            label="Requested Date"
            name="requested_date"
            rules={[
              {
                required: true,
                message: "Please select resignation date",
              },
            ]}
          >
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              defaultValue={dayjs(resignationDetails.startTime, "YYYY-MM-DD")}
              format={"YYYY-MM-DD"}
              name="requested_date"
              disabledDate={(current) => {
                const resignationDate = dayjs(
                  resignationDetails.resignation_date
                );
                const maxRequestedDate = getMaxRequestedDate(resignationDate);
                return (
                  current &&
                  (current < resignationDate || current > maxRequestedDate)
                );
              }}
              inputReadOnly={screenSize.width < 768}
            />
          </Form.Item>

          <Form.Item label="Recovery Days" name="recovery_days">
            <Input size="large" disabled />
          </Form.Item>

          <Form.Item
            label="Reason"
            name="reason"
            rules={[
              {
                required: true,
                message: "Please add Reason!",
              },
            ]}
          >
            <TextArea
              rows={5}
              placeholder="Enter Your Reason"
              className="leaveTextArea"
              onChange={
                (e) => "reason"
                // handleFormChange("reason", e.target.value)
              }
            />
          </Form.Item>

          <Button
            htmlType="submit"
            style={{
              color: "white",
              background: "#008080",
              border: "1px solid #008080",
              height: "40px",
              width: "100%",
              borderRadius: "2px",
            }}
          >
            {loading ? (
              <Spin
                indicator={<LoadingOutlined spin style={{ color: "white" }} />}
              />
            ) : (
              "Send Request"
            )}
            {/* Send Request */}
          </Button>
        </Form>
      </Modal>

      <Loader />
    </div>
  );
}

export default SideMenuWithHeader;
