import { Tooltip } from 'antd'
import React from 'react'

function ToolTip({ title, children }) {

    return (
        <Tooltip
            placement="bottom"
            color='#008080'
            title={<span style={{ color: "white" }}>{title}</span>}
        >
            {children}
        </Tooltip>
    )
}

export default ToolTip