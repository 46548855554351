import React from 'react'
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import * as pdfjsLib from 'pdfjs-dist';

pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js';

function Test() {
    return (
        <div>Test

            {/* <iframe
                src="https://testkomal-storage.s3.amazonaws.com/Test/test.pdf?response-content-disposition=inline&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQQS4ESUXCHX7F446%2F20241213%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20241213T094441Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=c33ab16bc5d0c835abbf98beef2f2c0a8779b4c8a3cdf0e98346dcbe1781ac1e"
                // src={pdfUrl}
                type="application/pdf"
                width="100%"
                height="600px"
            /> */}

            <div style={{ height: '500px', width: '100%' }}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js">
                    <Viewer fileUrl="https://docs.google.com/gview?embedded=true&url=https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" />
                </Worker>
            </div>
        </div>
    )
}

export default Test