import React from 'react'

import SideMenuWithHeader from '../component/SideMenuWithHeader/SideMenuWithHeader';
import MobileMenu from '../component/MobileMenu/MobileMenu';
import getScreenSize from "../utils/getScreenSize"

function ProtectedRoute(props) {
    const screenSize = getScreenSize();

    return (
        <>
            {
                screenSize.width <= 768 ? <MobileMenu content={props.component} menuTitle={props.menuTitle} showBackButton={props.showBackButton} /> : <SideMenuWithHeader content={props.component} />
            }
        </>
    )
}

export default ProtectedRoute